import LftLogotipo from '@lift/design-system-react-web/dist/components/LftLogotipo';
import LftSkeleton from '@lift/design-system-react-web/dist/components/LftSkeleton';
import LftTypography from '@lift/design-system-react-web/dist/components/LftTypography';
import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { Link, Navigate, Outlet } from 'react-router-dom';
import { useResponsividade } from '../../hooks/responsividade/responsividade.hook';
import { useCurso } from '../../stores/curso/curso.hook';
import { useUsuario } from '../../stores/usuario/usuario.hook';
import { $isLead, usuarioMigrado } from '../../stores/usuario/usuario.store';
import { capitalizeFormatter } from '../../utils/formatters/capitalize/capitalize.formatter';
import { Header } from '../header/header.component';
import { MenuAcessibilidade } from '../menu-acessibilidade';
import { MenuAvatar } from '../menu-avatar/menu-avatar.component';
import MenuSanduiche from '../menu-sanduiche/menu-sanduiche.component';
import ScreenError from '../screen-error';
import Sidebar from '../sidebar/sidebar.component';
import S from './app-container.styles';
import { usePageDatalayer } from './hooks/page-datalayer/page-datalayer.hook';
import { useUsuarioDatalayer } from './hooks/usuario-datalayer/usuario-datalayer';

export const AppContainer: React.FC = () => {
  const [renderConteudo, setRenderConteudo] = useState(true);

  useUsuarioDatalayer();
  usePageDatalayer();

  const { erro } = useUsuario();
  const { curso } = useCurso();
  const { desktop } = useResponsividade();
  const isLead = useStore($isLead);

  useEffect(() => {
    const subscription$ = usuarioMigrado.watch(() => {
      return setRenderConteudo(false);
    });

    return () => {
      subscription$.unsubscribe();
    };
  }, []);

  if (erro && erro.name !== 'NoContentException') {
    return <ScreenError.Fatal erro="ERRO_CARREGAR_DADOS_USUARIO" />;
  }

  const exibirSidebar = desktop;
  const exibirNomeCurso = desktop;

  if (renderConteudo) {
    return (
      <S.AppContainer>
        {exibirSidebar && (
          <S.SidebarContainer data-testid="sidebar">
            <Sidebar />
          </S.SidebarContainer>
        )}

        <S.MainContainer>
          <Header
            logo={
              <Link to="/">
                <LftLogotipo alt="Logotipo da marca" />
              </Link>
            }
            center={
              exibirNomeCurso && (
                <S.CursoWrapper>
                  {!isLead &&
                    (curso ? (
                      <>
                        <LftTypography component="caption">Curso</LftTypography>
                        <LftTypography component="paragraph" fontWeight="medium">
                          {capitalizeFormatter(curso.nome)}
                        </LftTypography>
                      </>
                    ) : (
                      <LftSkeleton shape="line" data-testid="skeleton-nome-curso" />
                    ))}
                </S.CursoWrapper>
              )
            }
            aside={
              desktop ? (
                <S.HeaderAsideContainer>
                  <MenuAcessibilidade dropdownPosition="bottom" />

                  <MenuAvatar />
                </S.HeaderAsideContainer>
              ) : (
                <MenuSanduiche />
              )
            }
          />

          <Outlet />
        </S.MainContainer>
      </S.AppContainer>
    );
  }

  return <Navigate to="/disciplinas" />;
};
