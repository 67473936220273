import { Theme } from '@emotion/react';
import styled from '@emotion/styled';

const Container = styled.div``;

const AvatarButton = styled.button<{ theme?: Theme; active: boolean }>`
  border: none;
  padding: 0;
  background: ${({ theme }) => theme.NeutralColorLightPure};

  min-width: 220px;
  max-width: 300px;
  height: 60px;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 16px;

  #dropdown-icon {
    transition: transform 0.5s ease-in-out;
    ${({ active }) => active && 'transform:rotate(-180deg)'}
  }
`;

const ModalContent = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 32px 0 8px;

  flex-direction: column;
  justify-content: space-between;
`;

const ScrollArea = styled.div`
  width: 100%;
  flex: 1;
  height: 19.875rem;
  max-height: 19.875rem;
  display: flex;
  justify-content: center;

  overflow-x: hidden;

  overflow-y: scroll;

  box-shadow: inset 0px -24px 24px -24px ${({ theme }) => theme.NeutralColorLightHigh};
`;

const Footer = styled.footer`
  padding-top: 24px;
  width: fit-content;
`;

const S = {
  Container,
  AvatarButton,
  ModalContent,
  ScrollArea,
  Footer,
};

export default S;
