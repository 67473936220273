import { Assert } from '@aura/core-lib';
import { useStore } from 'effector-react';
import { combineEvents } from 'patronum';
import { useCallback, useEffect } from 'react';
import { codigosCursosAtualizadosEvent } from '../../../../hooks/codigos-cursos-complementares/codigos-cursos-complementares.hook';
import { IdExterno, Usuario } from '../../../../models/usuario.model';
import { $credenciais, usuarioAtualizado } from '../../../../stores/usuario/usuario.store';
import {
  PushDadosUsuarioNivel1,
  PushDadosUsuarioNivel2,
  PushDadosUsuarioNivel3,
  TiposConteudo,
} from './usuario-datalayer.types';

export const useUsuarioDatalayer = () => {
  const idExterno = useStore($credenciais);
  Assert.nonNullable(idExterno);

  const obterTipoConteudo = (possuiDisciplinas: boolean, possuiCursosComplementares: boolean) => {
    if (possuiDisciplinas) {
      return possuiCursosComplementares ? TiposConteudo.DISCIPLINAS_E_CURSOS_COMPLEMENTARES : TiposConteudo.DISCIPLINAS;
    }

    return possuiCursosComplementares ? TiposConteudo.CURSOS_COMPLEMENTARES : TiposConteudo.NENHUM;
  };

  const pushDadosUsuarioDatalayerNivel1 = useCallback((idExterno: IdExterno) => {
    const pushData: PushDadosUsuarioNivel1 = {
      event: 'registration_data',
      details: {
        inscricao: {
          idInscricao: idExterno.atila ?? idExterno.inscricao,
          idCandidato: idExterno.candidato,
        },
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).dataLayer.push(pushData);
  }, []);

  const pushDadosUsuarioDatalayerNivel2 = useCallback((idExterno: IdExterno, usuario: Usuario) => {
    const pushData: PushDadosUsuarioNivel2 = {
      event: 'registration_data',
      details: {
        inscricao: {
          idInscricao: idExterno.atila ?? idExterno.inscricao,
          idCandidato: idExterno.candidato,
          tipoCurso: usuario?.curso?.tipo,
          curso: usuario?.curso?.nome,
          modalidade: usuario?.curso?.modalidade,
        },
        matricula: {
          codMatricula: usuario?.matriculaAcademica?.codigo,
        },
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).dataLayer.push(pushData);
  }, []);

  const pushDadosUsuarioDatalayerNivel3 = useCallback(
    (idExterno: IdExterno, usuario: Usuario, possuiDisciplinas: boolean) => {
      const pushData: PushDadosUsuarioNivel3 = {
        event: 'registration_data',
        details: {
          inscricao: {
            idInscricao: idExterno.atila ?? idExterno.inscricao,
            idCandidato: idExterno.candidato,
            tipoCurso: usuario?.curso?.tipo,
            curso: usuario?.curso?.nome,
            modalidade: usuario?.curso?.modalidade,
          },
          matricula: {
            codMatricula: usuario?.matriculaAcademica?.codigo,
          },
          conteudo: {
            tipoConteudo: obterTipoConteudo(possuiDisciplinas, true),
          },
        },
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).dataLayer.push(pushData);
    },
    [],
  );

  const registrarNivel2 = useCallback(() => {
    const watcher = usuarioAtualizado.watch((usuario) => {
      pushDadosUsuarioDatalayerNivel2(idExterno, usuario);
    });

    return { desinscrever: () => watcher() };
  }, [idExterno, pushDadosUsuarioDatalayerNivel2]);

  const registrarNivel3 = useCallback(() => {
    const $registrationDataEvents = combineEvents({
      events: { usuario: usuarioAtualizado, codigosCursos: codigosCursosAtualizadosEvent },
    });

    const watcher = $registrationDataEvents.watch((payload) => {
      pushDadosUsuarioDatalayerNivel3(
        idExterno,
        payload.usuario,
        (payload.usuario.curso?.disciplinas?.length ?? 0) > 0,
      );
    });

    return { desinscrever: () => watcher() };
  }, [idExterno, pushDadosUsuarioDatalayerNivel3]);

  useEffect(() => {
    pushDadosUsuarioDatalayerNivel1(idExterno);

    const watcherNivel2 = registrarNivel2();
    const watcherNivel3 = registrarNivel3();

    return () => {
      watcherNivel2.desinscrever();
      watcherNivel3.desinscrever();
    };
  }, [idExterno, pushDadosUsuarioDatalayerNivel1, registrarNivel2, registrarNivel3]);
};
