import { LftTypography } from '@lift/design-system-react-web/dist/components';
import { useStore } from 'effector-react';
import { $isLead, $usuario } from '../../stores/usuario/usuario.store';
import { capitalizeFormatter } from '../../utils/formatters/capitalize/capitalize.formatter';
import S from './dados-usuario.styles';
import { ModalidadeCurso, modalidadesFormatadas } from './dados-usuario.types';

const DadosUsuario = () => {
  const usuario = useStore($usuario);
  const isLead = useStore($isLead);

  if (!usuario) {
    return <></>;
  }

  const modalidade = !isLead && modalidadesFormatadas[usuario.curso?.modalidade as ModalidadeCurso];

  return (
    <S.Container>
      {!isLead && (
        <>
          <S.Section>
            <LftTypography component="title" size="small" variant="h1">
              Sua inscrição
            </LftTypography>

            <S.List>
              <div>
                <LftTypography component="paragraph" size="small">
                  Formação em
                </LftTypography>

                <LftTypography component="paragraph" size="small" fontWeight="bold">
                  {capitalizeFormatter(usuario.curso?.nome)}
                </LftTypography>
              </div>

              <div>
                <LftTypography component="paragraph" size="small">
                  Modelo de Ensino
                </LftTypography>

                <LftTypography component="paragraph" size="small" fontWeight="bold">
                  {modalidade}
                </LftTypography>
              </div>

              <div>
                <LftTypography component="paragraph" size="small">
                  Forma de Ingresso
                </LftTypography>

                <LftTypography component="paragraph" size="small" fontWeight="bold">
                  {capitalizeFormatter(usuario.curso?.tipo)}
                </LftTypography>
              </div>
            </S.List>
          </S.Section>
          <S.Divider />
        </>
      )}

      <S.Section>
        <LftTypography component="title" size="small" variant="h1">
          Seus dados
        </LftTypography>

        <S.List>
          <div>
            <LftTypography component="paragraph" size="small">
              Nome
            </LftTypography>

            <LftTypography component="paragraph" size="small" fontWeight="bold">
              {usuario.nome}
            </LftTypography>
          </div>

          <div>
            <LftTypography component="paragraph" size="small">
              E-mail
            </LftTypography>

            <LftTypography component="paragraph" size="small" fontWeight="bold">
              {usuario.email}
            </LftTypography>
          </div>
        </S.List>
      </S.Section>
    </S.Container>
  );
};

export default DadosUsuario;
