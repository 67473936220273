import { Assert } from '@aura/core-lib';
import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import useSWR, { mutate as globalMutate } from 'swr';
import { ConfigsManager } from '../../infra/configs/configs.manager';
import { consultarListaUsuarios } from '../../services/consultar-lista-usuarios/consultar-lista-usuarios.service';
import { $autenticacao } from '../autenticacao/autenticacao.store';
import { $credenciais, $isLead, $usuario, filtrarUsuario, listaUsuariosAtualizada } from './usuario.store';
import { UsuarioHookResult } from './usuario.types';

const {
  apiConfigs: { tempoDedupping },
} = ConfigsManager.getInstance();

export const useUsuario = (): UsuarioHookResult => {
  const isLead = useStore($isLead) ?? undefined;
  const { token } = useStore($autenticacao);
  const idExterno = useStore($credenciais);
  Assert.nonNullable(idExterno);

  const chaveConsulta = useMemo(() => {
    if (!token) return null;

    return isLead ? ['consultar-lista-usuarios-lead', idExterno] : ['consultar-lista-usuarios', idExterno];
  }, [token, isLead, idExterno]);

  const {
    data: listaUsuarios,
    isValidating,
    error: erro,
    mutate,
  } = useSWR(
    chaveConsulta,
    () => {
      Assert.nonNullable(token);
      return consultarListaUsuarios({ idExterno, token, isLead });
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      revalidateOnReconnect: true,
      revalidateOnMount: true,
      dedupingInterval: tempoDedupping,
      onSuccess: (data) => {
        listaUsuariosAtualizada(data);
      },
    },
  );

  const usuario = useStore($usuario);

  useEffect(() => {
    if (!usuario && listaUsuarios) {
      filtrarUsuario(listaUsuarios);
    }
  }, [listaUsuarios, usuario]);

  useEffect(() => {
    if (token && chaveConsulta) {
      globalMutate(chaveConsulta, undefined);
    }
  }, [token, chaveConsulta]);

  const pendente = useMemo(() => (!usuario && !erro) || (!!erro && isValidating), [usuario, erro, isValidating]);

  const retentar = useCallback(() => {
    mutate();
  }, [mutate]);

  return { usuario, pendente, erro, retentar };
};
