import axios from 'axios';
import { NoContentException } from '../../exceptions/no-content.exception';
import { ConfigsManager } from '../../infra/configs/configs.manager';
import { $isLead } from '../../stores/usuario/usuario.store';
import { ConsultarListaUsuariosService, ConsultarListaUsuariosServiceResponse } from './consultar-lista-usuarios.types';

const {
  apiConfigs: { api },
} = ConfigsManager.getInstance();

export const consultarListaUsuarios: ConsultarListaUsuariosService = async ({ idExterno, token }) => {
  const filtros = [];
  const isLead = $isLead.getState();

  if (isLead) {
    filtros.push(`usuarioLead=${isLead}`);
  } else {
    idExterno.candidato && filtros.push(`idExterno.candidato=${idExterno.candidato}`);
    idExterno.inscricao && filtros.push(`idExterno.inscricao=${idExterno.inscricao}`);
    idExterno.atila && filtros.push(`idExterno.atila=${idExterno.atila}`);
  }

  const queryParams = filtros.join('&');

  const url = `${api}/me?${queryParams}`;

  const authorization = `Bearer ${token}`;

  const { data, status } = await axios.get<ConsultarListaUsuariosServiceResponse[]>(url, {
    headers: { authorization },
  });

  if (status === 206) {
    throw new NoContentException();
  }

  return data;
};
