export enum TiposConteudo {
  NENHUM = '0',
  DISCIPLINAS = 'disciplinas',
  CURSOS_COMPLEMENTARES = 'complementar',
  DISCIPLINAS_E_CURSOS_COMPLEMENTARES = '2',
}

export interface PushDadosUsuarioNivel1 {
  event: 'registration_data';
  details: {
    inscricao: {
      idInscricao: number | string | null;
      idCandidato: number | null;
    };
  };
}

export interface PushDadosUsuarioNivel2 {
  event: 'registration_data';
  details: {
    inscricao: {
      idInscricao: number | string | null;
      idCandidato: number | null;
      tipoCurso: string | undefined;
      curso: string | undefined;
      modalidade: string | undefined;
    };
    matricula: {
      codMatricula: string | undefined;
    };
  };
}

export interface PushDadosUsuarioNivel3 {
  event: 'registration_data';
  details: {
    inscricao: {
      idInscricao: number | string | null;
      idCandidato: number | null;
      tipoCurso: string | undefined;
      curso: string | undefined;
      modalidade: string | undefined;
    };
    matricula: {
      codMatricula: string | undefined;
    };
    conteudo: {
      tipoConteudo: TiposConteudo;
    };
  };
}
