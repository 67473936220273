import { createEvent, createStore } from 'effector';
import { AuthorizerException } from '../../exceptions/authorizer.exception';
import { IdExterno, Usuario } from '../../models/usuario.model';
import { ConsultarListaUsuariosServiceResponse } from '../../services/consultar-lista-usuarios/consultar-lista-usuarios.types';
import { paraUsuario } from './mapper/para-usuario.mapper';

const filtrarUsuariosApi = (usuarios: ConsultarListaUsuariosServiceResponse[]) => {
  const usuariosMapeados = paraUsuario(usuarios);

  const credenciais = $credenciais.getState();

  const usuarioPorCandidato = credenciais?.candidato
    ? usuariosMapeados.filter((u) => u.idExterno && u.idExterno.candidato === credenciais.candidato)
    : [];

  if (usuarioPorCandidato.length > 0) {
    return usuarioPorCandidato[0];
  }

  const usuarioPorInscricao = credenciais?.inscricao
    ? usuariosMapeados.filter((u) => u.idExterno && u.idExterno.inscricao === credenciais.inscricao)
    : [];

  if (usuarioPorInscricao.length > 0) {
    return usuarioPorInscricao[0];
  }

  const usuarioPorAtila = credenciais?.atila
    ? usuariosMapeados.filter((u) => u.idExterno && u.idExterno.atila === credenciais.atila)
    : [];

  if (usuarioPorAtila.length > 0) {
    return usuarioPorAtila[0];
  }

  const usuarioPorEmail = credenciais?.email;
  const usuarioPorEmailMapeado = usuariosMapeados.find((u) => u.email === usuarioPorEmail);

  if (usuarioPorEmailMapeado) {
    return usuarioPorEmailMapeado;
  }

  throw new AuthorizerException();
};

// Eventos
export const autenticado = createEvent<IdExterno>();
export const usuarioMigrado = createEvent();

// listaUsuariosAtualizada é um evento que deve ser triggado SEMPRE e APENAS com dado quente do /me
export const listaUsuariosAtualizada = createEvent<ConsultarListaUsuariosServiceResponse[]>();
export const usuarioAtualizado = listaUsuariosAtualizada.map(filtrarUsuariosApi);

export const filtrarUsuario = createEvent<ConsultarListaUsuariosServiceResponse[]>();

// usuarioSelecionado é um evento que deve ser triggado sempre que a lista de usuarios usuario muda e um usuario é filtrado
export const usuarioSelecionado = createEvent<Usuario>();

// Stores
// O prefixo $ é usado para identificar uma store
export const $credenciais = createStore<IdExterno | null>(null).on(autenticado, (_, result) => result);

export const $usuario = createStore<Usuario | null>(null)
  .on(filtrarUsuario, (_, result) => filtrarUsuariosApi(result))
  .on(usuarioAtualizado, (_, result) => result);

$usuario.watch((value) => {
  if (value) {
    usuarioSelecionado(value);
  }
});

export const setIsLead = createEvent<boolean>();

// Store para armazenar o parâmetro
export const $isLead = createStore<boolean | null>(null).on(setIsLead, (_, isLead) => isLead);
